import Api from './Api';

const resource = 'notices';
export default {

    list:async(farm,params)=>{
        return Api().get(resource,{params});
    },
    create:async(notice)=>{
        return Api().post(resource,notice.value);
    },
    get:async(id)=>{
        return Api().get(`${resource}/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`${resource}/${id}`);
    },
    update:async notice=>{
        return Api().put(`${resource}/${notice.value.id}`,notice.value);
    },
}