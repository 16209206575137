export default {
        getUserLogin:()=>JSON.parse(localStorage.getItem('beefyUser')),
        getUser:(state)=>state.user,
        getRoles:state=>state.user.roles,
        isAdmin:()=>{
            const user  = JSON.parse(localStorage.getItem('beefyUser'))
            if(Array.isArray(user.roles)){
               return user.roles.includes('ROLE_ADMIN')
            }
            return false;
        },
        isAuthenticate:()=>{
           return JSON.parse(localStorage.getItem('beefyUser')) && localStorage.getItem('tokenAuths');
           },
        listUsers:(state)=>state.users,
}