import { createApp } from 'vue'
import App from './App.vue'
/**
 * Vue router
 */
import router from '@/router/index';
/**
 * talwindCss file
 */
import './index.css'
/**
 * Element plus
 */
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import es from "element-plus/lib/locale/lang/es";

/**
 * Store
 */

 import store from "@/store/index";

/**
 * VueTailwindDatepicker
 */
import VueTailwindDatepicker from 'vue-tailwind-datepicker';

import * as validators from '@vuelidate/validators'
import i18n  from '@/i18n';
const { t } = i18n.global;
// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators

// Create your i18n message instance. Used for vue-i18n@9
const withI18nMessage = createI18nMessage({ t })
// for vue-i18n@8
// const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const numeric = withI18nMessage(validators.numeric)
export const integer = withI18nMessage(validators.integer);
export const sameAs = withI18nMessage(validators.sameAs,{ withArguments: true })

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
// or you can provide the param at definition, statically
export const maxLength = withI18nMessage(validators.maxLength(10))
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';


const VueTelInputOptions = {
 mode: "international",
 placeholder:"Enter"



}
window.jsPDF = window.jspdf.jsPDF;

createApp(App)
        .use(router)
        .use(ElementPlus,{ locale: es })
        .use(store)
        .use(i18n)
        .use(VueTelInput,[VueTelInputOptions])
        .use(VueTailwindDatepicker)
        .mount('#app')
