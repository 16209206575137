import Services from "@/service/stable";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';
export default {

    listStables:async ({commit},params)=>{
        Services.list(params).then(resp=>{

            commit('setStables',resp.data['hydra:member']);
            commit('setTotalItems',resp.data['hydra:totalItems'])
        })
        .catch(error=>{
            Exception.exceptionError(error);
        })   
    },
    getStable:async({ commit }, id)=>{
        return await Services.get(id)
      .then(async response => {
        commit('setStable', response.data)
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
    },
    createStable:async(context,client)=>{
       return await Services.create(client).then(()=>{

            ElMessage.info("Operación exitosa")
          return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    updateStable:async(context,client)=>{
      return await  Services.update(client).then(()=>{
          ElMessage.info("Operación exitosa")
           return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    deleteStable:async({commit},id)=>{
        return await Services.delete(id).then(async response=>{
            
            commit('setStable',response.data);

            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
   




}