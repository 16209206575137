import LocationsService from "@/service/locations.js";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {

    listLocationsAction:async ({commit},params)=>{
        LocationsService.listLocations(params).then(resp=>{
            console.log("locations",resp);
            commit('setLocations',resp.data['hydra:member']);
            commit('setTotalItems',resp.data['hydra:totalItems'])
        })
        .catch(error=>{
            Exception.exceptionError(error);
        })   
    },
    createLocation:async({commit},loc)=>{
        return await LocationsService.create(loc).then(async response=>{
            
            commit('setLocation',response.data);

            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    updateLocation:async({commit},loc)=>{
        return await LocationsService.update(loc).then(async response=>{
            
            commit('setLocation',response.data);

            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    getLocation:async({commit},id)=>{
        return await LocationsService.get(id).then(async response=>{
            
            commit('setLocation',response.data);


            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    deleteLocation:async({commit,dispatch},id)=>{
        return await LocationsService.delete(id).then(async response=>{
            
            commit('setLocation',response.data);
            console.log("lcoation",response);
            dispatch('listLocationsAction');
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    getLocationsByFarm:async ({commit},farm)=>{
        return await LocationsService.getByFarm(farm)
            .then(response=>{
                console.log("LOCATIONS",response.data);
                return commit('setLocations',response.data['hydra:member'])
            })

            .catch(error=>Exception.exceptionError(error));
    }




}