import Locality from "@/service/locality";
import Exception from "@/store/actions/catcherror";

export default {
    listCommunities:async ({commit},params)=>{
        
      return await  Locality.listCommunities(params).then(resp=>{

            commit('setCommunities',resp.data['hydra:member']);
            commit('setTotalItems',resp.data['hydra:totalItems'])
            return true;
        })
        .catch(error=> {
            Exception.exceptionError(error);
            return false;
        })
    },
    listProvinces:async ({commit},params)=>{
     return await   Locality.listProvinces(params).then(resp=>{
            console.log("provinces",resp);
            commit('setProvinces',resp.data['hydra:member']);
            return true;
        })
        .catch(error=> {
            Exception.exceptionError(error);
            return false;
        })
    },
    listLocalities:async({commit},params)=>{
        
        return  await Locality.listAllComunities(params).then(async response=>{

            commit('setLocalities',response.data['hydra:member']);
            return true;
     }).catch(error=>{
        Exception.exceptionError(error);
        return false;
     })   
    },
   
}