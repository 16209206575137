import Service from '@/service/profile'
import Exception from '@/store/actions/catcherror';

export default {

    currentUserAction:async ({commit})=>{
        Service.getCurrentUser()
            .then(resp=>{
                commit('setUser',resp.data);
            })

            .catch(error=>{
            Exception.exceptionError(error);
        })
    },
    changePassword:async (context,body)=>{
      return   Service.changePassword(body.id,body)
            .then(resp=>{
                context.commit('setUser',resp.data);
                return true;
            })

            .catch(error=>{
                Exception.exceptionError(error);
                return false;
            })
    },
    forgotAction:async (context,body)=>{
        Service.forgot(body.email)
            .then(()=>{
                return true;
            })

            .catch(error=>{
                Exception.exceptionError(error);
            })
    },
}