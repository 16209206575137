import Vuex from 'vuex'
import UserAction from './actions/user';
import UserGetters from './getters/user';
import ClientActions from "./actions/client";
import ClientGetters from "./getters/client";
import UserMutation from './mutations/user';
import ClientMutation from './mutations/client';
import CommercialAction from './actions/commercial';
import CommercialMutation from './mutations/commercial';
import CommercialGetters from './getters/commercial';
import FarmsAction from './actions/farms';
import FarmMutations from './mutations/farms';
import FarmGetters from './getters/farms';
import FeedAction from './actions/feeds';
import FeedMutation from './mutations/feeds';
import FeedGetters from './getters/feeds';
import VaccineAction from './actions/vaccines';
import VaccineMutation from './mutations/vaccines';
import VaccinesGetters from './getters/vaccines';
import SupplierAction from './actions/suppliers';
import SupplierMutation from './mutations/suppliers';
import SupplierGetters from './getters/suppliers';
import DestinationAction from './actions/destinations';
import DestinationMutation from './mutations/destinations';
import DestinationGetters from './getters/destinations';
import VetActions from './actions/veterinarians';
import VetMutation from './mutations/veterinarians';
import VetGetters from './getters/veterinarians';
import LocalityAction from './actions/localities';
import LocalityMutations from './mutations/localities';
import LocalityGetters from './getters/localities';
import LocationActions from './actions/locations';
import LocationMutations from './mutations/locations';
import LocationGetters from './getters/locations';
import WarehousesActions from './actions/warehouses';
import WarehousesMutations from './mutations/warehouses';
import WarehousesGetters from './getters/warehouses';
import StableAction from './actions/stables';
import StableMutations from './mutations/stables';
import StableGetters from './getters/stables';
import AnimalAction from './actions/animal';
import AnimalMutations from './mutations/animal';
import AnimalGetters from './getters/animal';
import RacesAction from './actions/races';
import RacesMutations from './mutations/races';
import  RacesGetters from './getters/races';
import GruopAnimalAction from './actions/group';
import GroupAnimalMutation from './mutations/group';
import GroupAnimalGetters from './getters/group';
import Disease from './actions/disease';
import Prescription from './actions/prescription';
import NoticeActions from './actions/notice';
import NoticeMutation from './mutations/notice';
import NoticeGetters from './getters/notice';
import ProfileAction from './actions/profile';
import ProfileMutations from './mutations/profile';
import ProfileGetters from './getters/profile';
import Report from './actions/informe';

export default new Vuex.Store({
    state:{
        user:{
            email:'',password:'',roles:[],clients:[]
        },
        users:[],
        clients:[],
        client:{name:'',nif:'',comments:'',clientCode:''},
        commercial:{},
        commercials:[],
        farms:[],
        farm:'',
        feed:'',
        feeds:[],
        vaccine:'',
        vaccines:[],
        suppliers:[],
        supplier:'',
        destinations:[],
        destination:'',
        veterinarian:'',
        veterinarians:[],
        comunities:[],
        provinces:[],
        localities:[],
        locations:[],
        location:'',
        warehouses:[],
        warehouse:'',
        stables:[],
        stable:'',
        animals:[],
        animal:'',
        notices:[],
        notice:'',
        races:[],
        groupsAnimal:[],
        groupAnimal:{},
        goTo:'',
        totalItems:0,
        page:1,
        itemsPerPage:10


    },
    mutations: {
        ...UserMutation,
        ...ClientMutation,
        ...CommercialMutation,
        ...FarmMutations,
        ...FeedMutation,
        ...VaccineMutation,
        ...SupplierMutation,
        ...DestinationMutation,
        ...VetMutation,
        ...LocalityMutations,
        ...LocationMutations,
        ...WarehousesMutations,
        ...StableMutations,
        ...AnimalMutations,
        ...RacesMutations,
        ...GroupAnimalMutation,
        ...NoticeMutation,
        ...ProfileMutations,
        setGoTo:(state,val) => state.goTo = val,
        setTotalItems:(state,val) => state.totalItems = val,
        setPage:(state,val) => state.page =val,
        setItemsPerPage:(state,val) => state.itemsPerPage=val,

       
    },
    actions:{
        ...UserAction,
        ...ClientActions,
        ...CommercialAction,
        ...FarmsAction,
        ...FeedAction,
        ...VaccineAction,
        ...SupplierAction,
        ...DestinationAction,
        ...VetActions,
        ...LocalityAction,
        ...LocationActions,
        ...WarehousesActions,
        ...StableAction,
        ...AnimalAction,
        ...RacesAction,
        ...GruopAnimalAction,
        ...Disease,
        ...Prescription,
        ...NoticeActions,
        ...ProfileAction,
        ...Report,

       


    },
    getters:{
        ...UserGetters,
        ...ClientGetters,
        ...CommercialGetters,
        ...FarmGetters,
        ...FeedGetters,
        ...VaccinesGetters,
        ...SupplierGetters,
        ...DestinationGetters,
        ...VetGetters,
        ...LocalityGetters,
        ...LocationGetters,
        ...WarehousesGetters,
        ...StableGetters,
        ...AnimalGetters,
        ...RacesGetters,
        ...GroupAnimalGetters,
        ...NoticeGetters,
        ...ProfileGetters,
        geGoTo:(state)=>state.goTo,
        getTotalItems:(state)=>state.totalItems,
        getPage:state => state.page,
        getItemPerPage:state => state.itemsPerPage,
    },

})