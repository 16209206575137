import Api from './Api';

const resource = 'users';
export default {
    getCurrentUser:async ()=>Api().get(`user/current`),
    changePassword:async(id,body)=>{
        return Api().put(`${resource}/${id}`,body);
    },
    delete:async(id)=>{
        return Api().delete(`${resource}/${id}`);
    },
    forgot:async (email)=>
    {
        let route = "reset_password";
        let body = {email: email};

        return Api().post(route, body);

    }

}



