import Api from './Api';

const resource = 'animals';
export default {

    list:async(params)=>{
        return Api().get(resource,{params});
    },
    create:async(g)=>{
        return Api().post(resource,g.value);
    },
    get:async(id)=>{
        return Api().get(`${resource}/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`${resource}/${id}`);
    },
    update:async animal=>{
        return Api().put(`${resource}/${animal.value.id}`,animal.value);
    },
    weigh:async (body)=>{
        return Api().post('animals/batch/weight',body);
    },
    moveStable:async(body)=>{
        return Api().post('animals/batch/move',body);
    },
    moveExit: async body => {
        return Api().post('animals/batch/exit',body);
    },
}