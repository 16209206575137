import Service from '@/service/notice';
import Exception from "@/store/actions/catcherror";
import {ElMessage} from "element-plus";



export default {
    /***
     * List all notices
     */
    listNotices:async({commit},params)=>{
        return await Service.list(params).then(async response=>{

            commit('setNotices',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    getNoticeAction:async({commit},id)=> {
        return await Service.get(id).then(async response => {

            commit('setNotice', response.data);

            return true;
        }).catch(error => {
            Exception.exceptionError(error);
        })
    },
    createNotice:async({commit,dispatch},body)=>{
        return await Service.create(body).then(async response=>{

            commit('setNotice',response.data);
            ElMessage.info("Operación exitosa")
            dispatch('listNotices');
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    updateNotice:async({commit,dispatch},body)=>{
        return await Service.update(body).then(async response=>{

            commit('setNotice',response.data);

            dispatch('listNotices');
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    deleteNotice:async({commit,dispatch},id)=>{
        return await Service.delete(id).then(async response=>{

            commit('setNotice',response.data);

            dispatch('listNotices');
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },


}