import Api from './Api';

const resource = 'localities';
const communities = 'communities';
const provinces = 'provinces'
export default {
    
    listCommunities:async (params)=>{
        /* console.log("Params",params); */
        return Api().get(communities,{params});
    },
    listProvinces:async (params)=>{
        return Api().get(provinces,{params});
    },
    listAllComunities:async(params)=>{
        return await Api().get(resource,{params});
                
    },
    
}