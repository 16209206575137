const es = {
    sameAs:"El valor debe ser igual al otro valor",
    axios:{
        error:{
            login:'Credenciales inválidas',
            badserver:'Sin respuesta del servidor',
            401:'No está auorizado para realizar la acción',
            422:'El usuario ya existe en la aplicación',
            wrongPass:"La clave actual es incorrecta.",
        },
        info:{
            createUser:"Usuario creado correctamente",
        }
    }
}
export default es;