import axios from 'axios'


export default () => {
  const token = localStorage.getItem('tokenAuths');
  
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': 'Bearer ' + token
    },
  })
}