import Service from '@/service/animal';
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {
    /***
     * List all clients
     */
    listAnimals:async({commit},params)=>{
        return await Service.list(params).then(async response=>{

            commit('setAnimals',response.data['hydra:member']);
            console.log("Animals",response.data['hydra:member']);
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    /**
     * Add client
     */
    createAnimal:async(context,animal)=>{
      return  Service.create(animal).then(response=>{
            console.log("create",response);
            ElMessage.info("Operación exitosa")
            return response?.data;
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    actionGetAnimal:async({ commit }, id)=>{
        console.log("IDENT",id);
        return await Service.get(id)
            .then(async response => {
                commit('setAnimal', response.data)
            })
            .catch(error => {
                Exception.exceptionError(error);
            })
    },
    /**
     * Delete client by Id
     */
    deleteAnimal: async (context, id) => {
        return await Service.delete(id)
            .then(async response => {
                console.log('delete', response)
                return response?.data;
            })
            .catch(error => {
                Exception.exceptionError(error);
            })
    },
    /**
     * Update client by ID
     */
    updateAnimal (context, animal) {
        Service.update(animal)
            .then(response => {
                console.log('update animal ', response)

            })
            .catch(error => {
                Exception.exceptionError(error);
            })
    },
    /**
     * Weigh Animals
     * @param context
     * @param data
     */
    weighAnimal(context,data){
        Service.weigh(data)
            .catch(error => {
                Exception.exceptionError(error);
            })
    },
    moveStable(context,data){
        Service.moveStable(data).catch(error=>Exception.exceptionError(error));
    },
    moveExit(context,data){
       // console.log("AAAAA",data);
        Service.moveExit(data).catch(error=>Exception.exceptionError(error));
    },

}