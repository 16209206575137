import Api from './Api';

export default {

    listUsers:async(params)=>{
        return Api().get('clients',{params});
    },
    createUser:async(client)=>{
        return Api().post('clients',client.value);
    },
    getClient:async(id)=>{
        return Api().get(`clients/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`clients/${id}`);
    },
    update:async client=>{
        return Api().put(`clients/${client.value.id}`,client.value);
    },
}