import Farms from "@/service/farms";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';
export default {
    listFarms:async({commit},params)=>{
        
        return await Farms.listFarms(params).then(async response=>{
            
            commit('setFarms',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])

     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    createFarm:async({commit},farm)=>{
        return await Farms.create(farm).then(async response=>{
            
            commit('setFarm',response.data);
            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    updateFarm:async({commit},farm)=>{
        return await Farms.update(farm).then(async response=>{
            
            commit('setFarm',response.data);
           return true;

     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    deleteFarm:async({commit},id)=>{
        return await Farms.delete(id).then(async response=>{
            
            commit('setFarm',response.data);
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    getFarm:async({commit},id)=>{
        return await Farms.get(id).then(async response=>{
            
            commit('setFarm',response.data);
            console.log("farm",response);
            
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    }
}