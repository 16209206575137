import CommercialService from "@/service/commercial.js";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {

        /***
         * List all clients
         */
      listCommercials:async({commit},params)=>{
        return await CommercialService.listCommercials(params).then(async response=>{
                commit('setCommercials',response.data['hydra:member']);
                commit('setTotalItems',response.data['hydra:totalItems'])

            }).catch(error=>{
            Exception.exceptionError(error);
            })   
        },
       /**
     * Add commercial
     */
    createCommercial:async(context,comm)=>{

       return await CommercialService.create(comm).then(()=>{

            ElMessage.info("Operación exitosa")
            return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
     /**
     * Update commercial
     */
     updateCommercial:async(context,comm)=>{

        return await CommercialService.update(comm).then(()=>{
            ElMessage.info("Operación exitosa")
            return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
     /**
     * Update commercial
     */
     getCommercial:async({commit},id)=>{
       
       return await CommercialService.get(id).then(response=>{


            commit('setComercial', response.data)
        }).catch(error=>{
            Exception.exceptionError(error);
        })   
    },
      /**
   * Delete client by Id
   */
  deleteCommercial: async (context, id) => {
    return await CommercialService.delete(id)
      .then(() => {
          ElMessage.info("Operación exitosa")
         return true;
      })
      .catch(error => {
          Exception.exceptionError(error);
          return false;
      })
  },
    

}