import Destination from "@/service/destinations";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';
export default {
    listDestinations:async({commit},dest)=>{
        
        return await Destination.listDestinations(dest).then(async response=>{
            
            commit('setDestinations',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])

     }).catch(error=>{
            Exception.exceptionError(error);
         })
    },
    createDestination:async({commit},destination)=>{
        return await Destination.create(destination).then(async response=>{
            
            commit('setDestination',response.data);
            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    getDestination:async({ commit }, id)=>{
        return await Destination.get(id)
            .then(async response => {
                commit('setDestination', response.data)
                return true;
            })
            .catch(error => {
                Exception.exceptionError(error);
                return false;
            })
    },
    updateDestination:async(context,dest)=>{
        return await Destination.update(dest).then(()=>{
            return true;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
    deleteDestination:async({commit},id)=>{
        return await Destination.delete(id).then(async response=>{

            commit('setDestination',response.data);
            return true;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
}