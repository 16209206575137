import Api from './Api';

export default {
    
    listCommercials:async(params)=>{
        return await Api().get('commercials',{params});
    },
    create:async(comm)=>{
        return await Api().post('commercials',comm.value);
    },
    get:async(id)=>{
        return await Api().get(`commercials/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`commercials/${id}`);
    },
    update:async comm=>{
        return await Api().put(`commercials/${comm.value.id}`,comm.value);
    },
}