import Service from '@/service/group';
import { ElMessage } from 'element-plus';
import Exception from "@/store/actions/catcherror";


export default {

    /***
     * List all groups
     */
    listGroupsAnimals:async({commit},params)=>{
        return await Service.list(params).then(async response=>{

            commit('setGroupsAnimal',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])

        }).catch(error=>{
            Exception.exceptionError(error);
        })

    },
    /**
     * Add Group
     */
    createGroupAnimal:async({commit},group)=>{
        Service.create(group).then(response=>{
            console.log("create",response);
            commit("setGroupAnimal",response.data);
            ElMessage.info("Operación exitosa")
        }).catch(error=>{
            Exception.exceptionError(error);
        })
    },
    /**
     *
     */
    getGroupAnimalAction:async({ commit }, id)=>{
        return await Service.get(id)
            .then(async response => {
             commit('setGroupAnimal', response.data);
            })
            .catch(error => {

                Exception.exceptionError(error);
            })
    },
    /**
     * Get all animal inside group
     */
    getAnimalInGroup:async ({commit},params)=>{
        return await Service.getAnimalsInGroup(params)
            .then(async response => {
                commit('setAnimals', response.data['hydra:member']);
            })
            .catch(error => {

                Exception.exceptionError(error);
            })
    },
    getAnimalsInGroups:async (context,params)=>{
    return await Service.getAnimalsInGroups(params);

    },
    groupBatchExit:async (context,params)=>{
        return await Service.groupBatchExit(params);
    }

}