import Api from './Api';

export default {

    loggin:async (username,password)=>{
        return Api().post('login',{
            username,
            password
        })
    },

    listUsers:async(params)=>{
        console.log("PARAMS",params);
        return Api().get('users',{params});
    },
    createUser:async(user)=>{
        return Api().post('users',user.value);
    },
    updateUser:async user=>{
        return Api().put(`users/${user.value.id}`,user.value);
    },
    deleteUser:async(id)=>{
        return Api().delete(`users/${id}`);
    },
    getUser:async(id)=>{
        return Api().get(`users/${id}`);
    },
    changePassword:async (token,newPassword)=>{

            return Api().post(`reset_password/change`,{token,plainPassword:newPassword});
    }




}