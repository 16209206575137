import Client from '@/service/client';
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {
     /***
         * List all clients
         */
      listClients:async({commit},params)=>{
        return await Client.listUsers(params).then(async response=>{
            
            commit('setClients',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])
            console.log("Clients",response.data['hydra:member']);
     }).catch(error=>{
            Exception.exceptionError(error);
     })
    },
    /**
     * Add client
     */
    createClient:async(context,client)=>{
       return await Client.createUser(client).then(()=>{

            ElMessage.info("Operación exitosa")
           return true
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    actionGetClient:async({ commit }, id)=>{
        return await Client.getClient(id)
      .then(async response => {
        commit('setClient', response.data)
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
    },
     /**
   * Delete client by Id
   */
  deleteClient: async (context, id) => {
    return await Client.delete(id)
      .then( () => {
          ElMessage.info("Operación exitosa")
        return true;
      })
      .catch(error => {
          Exception.exceptionError(error);
          return false;
      })
  },
   /**
   * Update client by ID
   */
    updateClient ({dispatch}, client) {
      Client.update(client)
        .then(response => {
          console.log('update client ', response)
          if (response.data) dispatch('listClients')
        })
        .catch(error => {
            Exception.exceptionError(error);
        })
    },
}