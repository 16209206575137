import Services from "@/service/warehouses.js";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';
export default {

    listWarehouses:async ({commit},params)=>{
        Services.list(params).then(resp=>{

            commit('setWarehouses',resp.data['hydra:member']);
            commit('setTotalItems',resp.data['hydra:totalItems'])
        })
        .catch(error=>{
            Exception.exceptionError(error);
        })   
    },
    getWarehouse:async({ commit }, id)=>{
        return await Services.get(id)
      .then(async response => {
        commit('setWarehouse', response.data)
          return response.data;
      })
      .catch(error => {
          Exception.exceptionError(error);
          return false;
      })
    },
    createWarehouse:async(context,client)=>{
      return await  Services.create(client).then(response=>{

            if(response.data){
                ElMessage.info("Operación exitosa")
               return true;
            }
            return false;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    updateWarehouse:async(context,client)=>{
     return await  Services.update(client).then(()=>{
         ElMessage.info("Operación exitosa")
           return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    deleteWarehouse:async({commit},id)=>{
        return await Services.delete(id).then(async response=>{
            
            commit('setWarehouse',response.data);

            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
   




}