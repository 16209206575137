import Supplier from "@/service/suppliers";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';


export default {
    listSuppliers:async({commit},params)=>{
        
        return await Supplier.listSuppliers(params).then(async response=>{
            
            commit('setSuppliers',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    createSupplier:async({commit},supplier)=>{
        return await Supplier.create(supplier).then(async response=>{
            
            commit('setSupplier',response.data);
            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    getSupplier:async({ commit }, id)=>{
        return await Supplier.get(id)
            .then(async response => {
                commit('setSupplier', response.data)
                return true;
            })
            .catch(error => {
                Exception.exceptionError(error);
                return false;
            })
    },
    updateSupplier:async(context,supp)=>{
        return await Supplier.update(supp).then(()=>{
            return true;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
    deleteSupplier:async({commit},id)=>{
        return await Supplier.delete(id).then(async response=>{

            commit('setSupplier',response.data);
            return true;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
}