import Service from "@/service/informe"
import Exception from "@/store/actions/catcherror";
export default {
    loadReport : async (context,params) =>{
        console.log("PArams",params);
        return await Service.loadReport(params).then(resp=>resp).catch(error=>{
            Exception.exceptionError(error);
            return false
        });
    }
}