import User from '@/service/user';
import { ElMessage } from 'element-plus';
import router from '@/router';
import Exception from '@/store/actions/catcherror';

export default {
  /**
   * Login to app
   */
  loggin: ({  commit }, params) => {
    console.log('Params', params)
    User.loggin(params.username, params.passsword)
      .then(response => {
        const user = response.data.user
        console.log("USER LOGIN",user);
        commit('setUserLogin', user)
        commit('setToken', response.data.token)

        if (
          Array.isArray(user.roles) &&
          user.roles.includes('ROLE_ADMIN')
        )
          router.push({ name: 'home-admin' })
        else router.push({ name: 'home-user' })
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
  },
  /**
   * Loggout of app
   */
  loggout: ({ commit }) => {
    localStorage.removeItem('beefyUser')
    commit('setUser', '')
    commit('setToken', '')
    router.push({ name: 'Loggin' })
  },
  /**
   * List User
   */
  listUsers: async ({ commit }, params) => {
    return User.listUsers(params)
      .then(response => {
        console.log('user', response.data['hydra:member'])
        commit('setListUsers', response.data['hydra:member'])
        commit('setTotalItems',response.data['hydra:totalItems'])
        return response.data['hydra:member']
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
  },
  /**
   * Create User
   */
  createUser:async (context, user) =>{
    return await User.createUser(user)
      .then(() => {
          ElMessage.info("Operación exitosa")
        return true;
      })
      .catch(error => {
          Exception.exceptionError(error);

        return false;
      })
  },
  /**
   * Update user by ID
   */
  async updateUser (context, user) {
    return await User.updateUser(user)
      .then(() => {

          ElMessage.info("Operación exitosa")
          return true;

      })
      .catch(error => {
        console.log('Error login', error)
          Exception.exceptionError(error);
        return false;
      })
  },
  /**
   * Delete user by Id
   */
  deleteUser: async ({ dispatch }, id) => {
    return await User.deleteUser(id)
      .then(async response => {
        console.log('delete', response)

        await dispatch('listUsers').then(resp => resp)
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
  },
  /**
   * Get user by ID
   */
  actionUser: async ({ commit }, id) => {
    return await User.getUser(id)
      .then(async response => {
        console.log("User", response.data);
        commit('setUser', response.data)
        return true;
      })
      .catch(error => {
          Exception.exceptionError(error);
      })
  },
  actionResetPassword: async (context, params) => {

    return await User.changePassword(params.id,params.password)
        .then(async ()=> {

          return true;
        })
        .catch(error => {
            Exception.exceptionError(error);
        })
  },

}
