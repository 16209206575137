import { createI18n } from 'vue-i18n';
import es from '/src/locales/es.js';
import validations_es from "@/locales/validations.es";



const i18n = createI18n({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    globalInjection: true,
    messages: {
        es: {
            validations:validations_es,
            message: es
        },

    }
})

export default i18n