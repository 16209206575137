import Services from "@/service/races";
import Exception from '@/store/actions/catcherror';

export default {
    listRaces:async ({commit},params)=>{
        Services.list(params).then(resp=>{

            commit('setRaces',resp.data['hydra:member']);
            commit('setTotalItems',resp.data['hydra:totalItems'])
        })
            .catch(error=>{
                Exception.exceptionError(error);
            })
    },

}