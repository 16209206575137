import Vets from "@/service/veterinarians";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {
    listVeterinarians:async({commit},params)=>{
        
        return await Vets.listVeterinarians(params).then(async response=>{
            
            commit('setVeterinarians',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    createVeterinarian:async({commit},vets)=>{
        return await Vets.create(vets).then(async response=>{
            
            commit('setVeterinarian',response.data);
            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    getVeterinarian:async({ commit }, id)=>{
        return await Vets.get(id)
            .then(async response => {
                commit('setVeterinarian', response.data)
                return true;
            })
            .catch(error => {
                Exception.exceptionError(error);
                return false;
            })
    },
    updateVeterinarian:async(context,vet)=>{
        return await Vets.update(vet).then(()=>{
            ElMessage.info("Operación exitosa")
            return true;

        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
    deleteVeterinarian:async({commit},id)=>{
        return await Vets.delete(id).then(async response=>{

            commit('setVeterinarian',response.data);
            return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })
    },
}