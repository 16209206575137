export default {
    setUserLogin:(state,user)=>
        {   console.log(state);
            localStorage.setItem('beefyUser',JSON.stringify(user))
        },
        setUser:(state,user)=>{
            
            state.user = user;
        },
        setToken:(state,token)=>localStorage.setItem('tokenAuths',token),
        setListUsers:(state,users)=>state.users = users,
}