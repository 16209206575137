import Feed from "@/service/feed";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {
    listFeeds:async({commit},params)=>{
        
        return await Feed.listFeeds(params).then(async response=>{
            
            commit('setFeeds',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])

     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    createFeed:async({commit},feed)=>{
        return await Feed.create(feed).then(async response=>{
            
            commit('setFeed',response.data);
            ElMessage.info("Operación exitosa")
           return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    updateFeed:async({commit},feed)=>{
        return await Feed.update(feed).then(async response=>{
            
            commit('setFeed',response.data);
            ElMessage.info("Operación exitosa")
           return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    deleteFeed:async({commit},id)=>{
        return await Feed.delete(id).then(async response=>{
            
            commit('setFeed',response.data);
            
           return true;
     }).catch(error=>{

            Exception.exceptionError(error);
            return false;
     })
    },
    getFeed:async({ commit }, id)=>{
        return await Feed.get(id)
      .then(async response => {
        commit('setFeed', response.data)
      })
      .catch(error => {

          Exception.exceptionError(error);
      })
    },
    feedToAnimal:async (context,obj)=>{
        return await Feed.feedToAnimal(obj)
            .catch(error => {
                Exception.exceptionError(error);
            })
    }

}