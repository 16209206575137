import Api from './Api';
import axios from "axios";

const resource = 'groups';
export default {

    list: async (params) => {
        return Api().get(resource, {params});
    },
    create: async (group) => {
        return Api().post(resource, group);
    },
    get: async (id) => {
        return Api().get(`${resource}/${id}`);
    },
    delete: async (id) => {
        return Api().delete(`${resource}/${id}`);
    },
    update: async animal => {
        return Api().put(`${resource}/${animal.value.id}`, animal);
    },

    getAnimalsInGroup:async params =>{

        return  Api().get(`animals?pagination=false&farm=${params.farm}&group=${params.group}`);
    },
    getAnimalsInGroups:async params =>{
        console.log("Params",params);
        const request = params.groups.map(v=>Api().get(`animals?pagination=false&farm=${params.farm}&group=${v}`))
        return await axios.all(request);
    },
    groupBatchExit: async (body) => {
        return Api().post('groups/batch/exit', body);
    },

}