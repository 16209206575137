import Api from './Api';
const resource = 'locations';

export default {
   
    listLocations:async(params)=>{
        return Api().get(resource,{params});
    },
    create:async(obj)=>{
        return Api().post(resource,obj.value);
    },
    get:async(id)=>{
        return Api().get(`${resource}/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`${resource}/${id}`);
    },
    update:async obj=>{
        return Api().put(`${resource}/${obj.value.id}`,obj.value);
    },
    getByFarm:async farm=>{
        return Api().get(`locations?pagination=false&farm=${farm}`);
    }
}