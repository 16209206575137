<template>
  <div class="flex">
   
      <RouterView name="LeftSidebar" class="order-1"></RouterView>
      <RouterView class="order-2"></RouterView>
      <RouterView name="RightSidebar" class="order-3" ></RouterView>
    
    
    
  </div>
</template>
<style lang="scss" scoped>

</style>

