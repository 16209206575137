import Api from './Api';

export default {

    listFarms:async(params)=>{
        return Api().get('farms',{params});
    },
    create:async(obj)=>{
        return Api().post('farms',obj.value);
    },
    get:async(id)=>{
        return Api().get(`farms/${id}`);
    },
    delete:async(id)=>{
        return Api().delete(`farms/${id}`);
    },
    update:async obj=>{
        return Api().put(`farms/${obj.value.id}`,obj.value);
    },
}