import {ElMessage} from "element-plus";
import i18n from '@/i18n';
import store from '@/store';
const logout =  () => {
    store.dispatch('loggout')
}
export default {

    exceptionError:(error)=>{

        console.log('Error feed', error.response)
        if(!error || !error.response || !error.response.status){
            ElMessage.error(i18n.global.t('message.axios.error.badserver'));
            logout();
            return;
        }
        switch (error.response.status){
            case 401:
                if(error.response.data && error.response.data.message &&  error.response.data.message ==="Invalid credentials.")
                {
                    ElMessage.error(i18n.global.t('message.axios.error.login'))
                    break;
                }
                ElMessage.error(i18n.global.t('message.axios.error.401'))
                 logout();
                break;
            case 400:
                if(error.response.data && error.response.data['hydra:description'] && error.response.data['hydra:description'] ==="Wrong password.")
                {
                    ElMessage.error(i18n.global.t('message.axios.error.wrongPass'))
                    break;
                }
                ElMessage.error(i18n.global.t('message.axios.error.badserver'))
                break;
            case 422:ElMessage.error(i18n.global.t('message.axios.error.422'))
                //store.dispatch('loggout');
                break;
            default:ElMessage.error(i18n.global.t('message.axios.error.badserver'))
                //logout();
                break;

        }



       /* if (error.response && error.response.data.code === 401) {
            ElMessage.error(
                i18n.global.t('message.axios.error.401')
            )
            store.dispatch('loggout');
        } else ElMessage.error(i18n.global.t('message.axios.error.badserver'))*/
    }


}