import Vaccine from "@/service/vaccines";
import { ElMessage } from 'element-plus';
import Exception from '@/store/actions/catcherror';

export default {
    listVaccines:async({commit},params)=>{
        
        return await Vaccine.listVaccines(params).then(async response=>{
            
            commit('setVaccines',response.data['hydra:member']);
            commit('setTotalItems',response.data['hydra:totalItems'])
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    createVaccine:async({commit},vacc)=>{
        return await Vaccine.create(vacc).then(async response=>{
            
            commit('setVaccine',response.data);
            ElMessage.info("Operación exitosa")
            return true;
     }).catch(error=>{
            Exception.exceptionError(error);
            return false;
     })   
    },
    updateVaccine:async(context,vac)=>{
       return await Vaccine.update(vac).then(()=>{
          return true;
        }).catch(error=>{
            Exception.exceptionError(error);
            return false;
        })   
    },
    getVaccine:async({ commit }, id)=>{
        return await Vaccine.get(id)
      .then(async response => {
        commit('setVaccine', response.data)
          return true;
      })
      .catch(error => {
          Exception.exceptionError(error);
          return false;
      })
    },
    deleteVacc:async({commit},id)=>{
        return await Vaccine.delete(id).then(async response=>{
            
            commit('setVaccine', response.data)
            return true;
           
     }).catch(error=>{
            Exception.exceptionError(error);
     })   
    },
    vaccineToAnimal:async(context,obj)=>{
        return await Vaccine.vaccineToAnimal(obj).catch(error=>{
            Exception.exceptionError(error);
        })
    }
}